import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import title from "../images/SlapstickFighter/Title.png"
import { Container, Button /*, Row, Col*/ } from "reactstrap"
import "../css/slapstick-fighter.css"
import ReactPlayer from "react-player"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faEnvelope,
    faCircle,
} from "@fortawesome/free-solid-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import "../../node_modules/text-image/dist/text-image.js"
import Header from "../components/slapstickFighter/header"

class SlapstickFighter extends React.Component{

    constructor(props) {
        super(props)
        // Don't call this.setState() here!
        this.state = {
            emailImageSrc: null
        }
    }

    componentDidMount() {
        // create new TextImage object with customize style
        var style = {
            font: "Sans-serif",
            align: "left",
            color: "#38c065",
            size: 11,
        }
        
        var textImage = window.TextImage(style)

        // convert text message to image element
        var emailData = textImage.toDataURL(
            atob("c3VwcG9ydEBjeWJlcm5hdGUuY29t")
        )

        this.setState({
            emailImageSrc: emailData
        })
    }

    render(){
        return(
            <Layout header={Header}>
                <SEO title="Slapstick Fighter" />
                <Container fluid className="slapstick hero main-section">
                    <img
                        className="mx-auto animated fadeInDown delay-05s"
                        src={title}
                        alt="slapstick fighter title"
                    />
                    <h1 className="mx-3 mt-1 animated fadeIn delay-07s">
                        Physics based fighting
                    </h1>
                </Container>

                <Container fluid className="slapstick main-section video">
                    <div className="d-flex justify-content-center animated fadeIn delay-05s">
                        <ReactPlayer
                            url="https://vimeo.com/341957401/c963ac0e27"
                            playing
                        />
                    </div>
                </Container>

                <Container fluid className="slapstick main-section store-link">
                    <div>
                        <h2 className="wow animated fadeIn" data-wow-offset="100">
                            Available now on iPhone & iPad
                        </h2>
                        <Button outline size="lg" color="success" onClick={()=>window.location.href="https://apps.apple.com/us/app/slapstick-fighter/id1445642543"}>Get it now on iOS</Button>
                    </div>
                </Container>

                <Container fluid className="slapstick main-section newsletter">
                    <iframe className="sendInBlue" title="sub" src="https://sibforms.com/serve/MUIEAMJzbkPeBf0dboci-rRReoEEs1AvqyGNBQELuhYkKgXHQkuQhaEFjzOvYc4j7H50iLKMVpoDW6-nt6_yxiN0WOGRSLrJtmoEajBbUiQYZ8iUg-NsGEZ6091804WBg6_ier_AiDgi3XaOdgWcJ4WJD-f_8jywZnnmej-n93rGdqz5cqVrKgR_da1VJEqB3VCd1fPV9SJ3wwhG" frameborder="0" scrolling="no" allowfullscreen style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%'}}></iframe>
                </Container>

                <Container fluid className="slapstick main-section features">
                    <div>
                        <h2 className="wow animated fadeIn" data-wow-offset="100">
                            features
                        </h2>
                    </div>
                    <div
                        className="features-box mx-auto wow animated fadeInLeft"
                        data-wow-offset="100"
                    >
                        <div className="features-info-box">
                            <p><FontAwesomeIcon icon={faCircle} /> Same-device multiplayer</p>
                        </div>
                        <div className="features-info-box">
                        <p><FontAwesomeIcon icon={faCircle} /> Two button controls</p>
                        </div>
                        <div className="features-info-box">
                        <p><FontAwesomeIcon icon={faCircle} /> Surprising amount of moves</p>
                        </div>
                        <div className="features-info-box">
                        <p><FontAwesomeIcon icon={faCircle} /> Single hit knockouts</p>
                        </div>
                        <div className="features-info-box">
                        <p><FontAwesomeIcon icon={faCircle} /> Easy to play, hard to master</p>
                        </div>
                    </div>
                </Container>

                <Container fluid className="slapstick main-section contact">
                    <div>
                        <h2
                            id="support"
                            className="wow animated fadeIn"
                            data-wow-offset="100"
                        >
                            Support
                        </h2>
                    </div>
                    <div
                        className="contact-box mx-auto wow animated fadeInLeft"
                        data-wow-offset="100"
                    >
                        <div className="contact-info-box clearfix">
                            <h3>
                                <FontAwesomeIcon icon={faEnvelope} /> Email:
                            </h3>
                            <span>
                                <img src={this.state.emailImageSrc} alt="" />
                            </span>
                        </div>
                        <div className="contact-info-box clearfix">
                            <h3>
                                <FontAwesomeIcon icon={faTwitter} /> Twitter:
                            </h3>
                            <span>
                                <a href="https://twitter.com/chris_suffern">
                                    @chris_suffern
                                </a>
                            </span>
                        </div>
                    </div>
                </Container>
            </Layout>
        )
    }
}

export default SlapstickFighter
